<template>
  <v-container fluid>
    <v-card outlined class="px-4 pb-0 mx-5 mb-5">
      <v-card-title>{{ $t("signAutomation.activateTitle") }}</v-card-title>

      <v-card-text>
        {{ $t("signAutomation.activateSubtitle") }}
      </v-card-text>

      <Alert
        v-if="!hasConectaControl"
        dense
        color="warning"
        class="mb-0 ml-4"
        :actionText="'Entrar em contato'"
        :actionIcon="''"
        @action="scheduleMeeting"
      >
        {{ $t("signAutomation.alertControlPlan") }}
      </Alert>
      <Alert
        dense
        v-if="!company.is_sign_automation_active && hasConectaControl"
        color="warning"
        class="mb-0 ml-4"
      >
        {{ $t("signAutomation.alertAutomationNotActive") }}
      </Alert>
      <Alert
        dense
        v-if="company.is_sign_automation_active"
        color="success"
        class="mb-0 ml-4"
      >
        <p v-html="$t('signAutomation.alertAutomationActive')"></p>
      </Alert>
      <v-card-actions class="px-4 mt-2">
        <v-btn
          x-large
          @click="changeSignAutomation"
          class="px-5 text-none text-body-1 font-weight-medium"
          color="accent"
          :loading="loading"
        >
          <v-icon
            v-text="
              company.is_sign_automation_active
                ? 'mdi-autorenew-off'
                : 'mdi-autorenew'
            "
            left
            size="28"
            class="mr-3"
          />
          {{ btnName }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card outlined class="px-4 pb-6 mx-5">
      <v-card-title>
        {{ $t("signAutomation.scheduledSignatures") }}
      </v-card-title>
      <v-card-text class="mx-0 px-0">
        <v-data-table
          :items="signatureSchedules"
          :headers="headers"
          :items-per-page="50"
          :loading="loading"
          single-expand
          :height="tableHeight"
          fixed-header
          show-expand
          item-key="key"
          item
        >
          <!-- AUTHOR HEADER-->
          <template v-slot:[`header.author`]="{ header }">
            {{ header.text }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-help-circle'"
                  small
                />
              </template>
              <span class="font-weight-medium">
                {{ $t("signAutomation.authorTooltip") }}
              </span>
            </v-tooltip>
          </template>

          <!-- CREATED AT -->
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateAndHour(item.created_at, true) }}
          </template>

          <template v-slot:[`item.schedule_date`]="{ item }">
            {{ formatDateAndHour(item.schedule_date, false) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-icon
              left
              dense
              v-text="getStatus(item.status, 'icon')"
              :color="getStatus(item.status, 'color')"
            />
            {{ getStatus(item.status, "text") }}
            <v-tooltip bottom v-if="item.status === 'CREATED'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-help-circle'"
                  small
                />
              </template>
              <span class="font-weight-medium">
                {{ $t("signAutomation.errorTooltip") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.author`]="{ item }">
            <Author :author="item.author || {}" />
          </template>

          <template v-slot:[`item.template`]="{ item }">
            <span>
              {{ getTemplate(item.template_key)?.name }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  data-testid="options"
                  icon
                  class="ml-auto"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-dots-vertical'" />
                </v-btn>
              </template>
              <v-list dense class="py-0">
                <v-list-item
                  class="text-body-2 font-weight-medium"
                  @click="setTemplatePreview(item.template_key)"
                >
                  {{ $t("signAutomation.signaturePreview") }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  :disabled="item.status !== 'SCHEDULED'"
                  @click="showModal(item.key)"
                  class="text-body-2 font-weight-medium"
                >
                  {{ $t("signAutomation.cancelSchedule") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pl-4 pt-4 pb-4 grey lighten-4">
              <div>
                <li>
                  {{ $t("signAutomation.applyFor") }}:
                  {{ item.to_users.join(", ") }}
                </li>
              </div>
            </td>
          </template>

          <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          >
            <v-icon
              :class="[
                'v-data-table__expand-icon',
                { 'v-data-table__expand-icon--active': isExpanded },
              ]"
              @click.stop="expand(!isExpanded)"
              >$expand</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <SimpleDialog
      :title="previewName"
      :show="showPreview"
      @close="showPreview = false"
      actionText="Fechar"
      @action="showPreview = false"
      width="800"
    >
      <template v-slot:body>
        <TemplatePreview
          :html="htmlTemplate"
          :infoToRender="currentUser"
          signature-only
        />
      </template>
    </SimpleDialog>
    <ConfirmationDialog
      :show="showCancelConfirm"
      :actionText="target"
      :loading="loading"
      width="auto"
      @close="showCancelConfirm = false"
      @ok="deleteSchedule()"
      hideReversibleText
      dense
    />
  </v-container>
</template>
<script>
import { tableHeight } from "@/helpers/variables/tablesDefault";
import { formatDateAndHour } from "@/helpers/services/utils";
import Author from "@/components/logs/cells/Author.vue";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TemplatePreview from "@/components/sign/general/TemplatePreview.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";

export default {
  name: "SignAutomation",
  components: {
    Author,
    TemplatePreview,
    ConfirmationDialog,
  },
  data() {
    return {
      loading: false,
      tableHeight,
      template_key: "",
      showPreview: false,
      htmlTemplate: "",
      templateName: "",
      status: {
        CREATED: {
          text: this.$t("signAutomation.status.created"),
          icon: "mdi-shield-alert",
          color: "warning",
        },
        SCHEDULED: {
          text: this.$t("signAutomation.status.scheduled"),
          icon: "mdi-clock",
          color: "accent",
        },
        EXECUTED: {
          text: this.$t("signAutomation.status.executed"),
          icon: "mdi-shield-check",
          color: "success",
        },
        DELETED: {
          text: this.$t("signAutomation.status.deleted"),
          icon: "mdi-shield-off",
          color: "error",
        },
      },
      showCancelConfirm: false,
      itemKey: "",
    };
  },
  methods: {
    ...mapMutations(["setSnackBar", "setCompany"]),
    ...mapActions([
      "getSignatureSchedules",
      "getDefaultTemplates",
      "getCustomTemplates",
      "deleteSignatureSchedule",
      "verifyHubspotToken",
    ]),

    formatDateAndHour,

    showModal(key) {
      this.itemKey = key;
      this.showCancelConfirm = true;
    },

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from sign automation";

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      this.verifyHubspotToken();
    },

    async changeSignAutomation() {
      let payload = {
        is_sign_automation_active: !this.company.is_sign_automation_active,
      };
      const url = `${process.env.VUE_APP_API_BASE_URL}/company`;
      this.loading = true;
      this.$axios
        .put(url, payload, {
          headers: { Authorization: this.token },
        })
        .then(({ data }) => {
          this.setCompany(data);
          this.setSnackBar({
            message: this.company.is_sign_automation_active
              ? this.$t("signAutomation.successDeactivate")
              : this.$t("signAutomation.successActivate"),
            show: true,
            color: "success",
          });
        })
        .catch((e) => {
          console.error("changeSignAutomation(): ", e);
          this.setSnackBar({
            message: this.$t("signAutomation.error"),
            show: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatus(status, type) {
      return this.status[status][type];
    },
    setTemplatePreview(key) {
      const template = this.getTemplate(key);
      this.htmlTemplate = template.html;
      this.templateName = template.name;
      this.showPreview = true;
    },
    getTemplate(key) {
      return this.allTemplates.find((item) => item.id === key);
    },
    deleteSchedule() {
      this.loading = true;
      this.deleteSignatureSchedule(this.itemKey)
        .then(() => {
          this.setSnackBar({
            message: this.$t("signAutomation.successOnCancel"),
            show: true,
            color: "success",
          });
        })
        .catch((e) => {
          console.error("deleteSignatureSchedule(): ", e);
          this.setSnackBar({
            message: this.$t("signAutomation.errorOnCancel"),
            show: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.itemKey = "";
          this.showCancelConfirm = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "hasConectaControl",
      "company",
      "token",
      "currentUser",
      "signatureSchedules",
      "currentUser",
      "allTemplates",
    ]),
    btnName() {
      return this.company.is_sign_automation_active
        ? this.$t("signAutomation.deactivate")
        : this.$t("signAutomation.activate");
    },
    previewName() {
      return this.$t("signAutomation.previewSignatureName", {
        name: this.templateName,
      });
    },

    target() {
      return this.$t("signAutomation.cancelTitle");
    },
    headers() {
      return [
        {
          value: "created_at",
          text: this.$t("tableHeaders.registrationDate"),
          width: 180,
          sortable: false,
        },
        {
          value: "schedule_date",
          text: this.$t("tableHeaders.scheduleDate"),
          width: 180,
          sortable: false,
        },
        {
          value: "status",
          text: this.$t("tableHeaders.situation"),
          width: 180,
          sortable: false,
        },
        {
          value: "author",
          text: this.$t("tableHeaders.author"),
          sortable: false,
          width: 150,
        },
        {
          value: "template",
          text: this.$t("tableHeaders.signature"),
          sortable: false,
          width: 200,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          width: 20,
        },
      ];
    },
  },
  async beforeMount() {
    await this.getSignatureSchedules();
    if (!this.allTemplates.length) {
      await this.getDefaultTemplates();
      await this.getCustomTemplates();
    }
  },
};
</script>
